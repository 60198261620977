<template>
  <section class="hero">
    <div class="overlay"></div>
    <div class="smoke-overlay"></div>
    <div class="hero-content flex flex-col lg:flex-row items-center lg:items-start">
      <div class="left-content text-center lg:text-left lg:w-1/2 p-6 lg:p-12">
        <h1 class="text-3xl lg:text-5xl font-bold text-gray-800 leading-tight">Innovative Service for Seamless Success</h1>
        <p class="text-lg lg:text-xl mt-4 text-gray-600">Your Field Supplies and Serviced, On Time, Every Time, Without the Hassle.</p>
        <div class="button-group mt-6 flex flex-col lg:flex-row">
          <button class="cta-button mb-2 lg:mb-0 lg:mr-4" style="backgroundColor: #990000">Apply to Sign Up</button>
          <button class="cta2-button">Sign in to your Lomac Service Center</button>
        </div>
      </div>
      <div class="right-content lg:w-1/2 p-6 lg:p-12">
        <img src="../assets/images/2.png" alt="Right Image" class="right-image w-full h-auto">
      </div>
    </div>
  </section>
  <Download />
</template>
 
<script>
import axios from 'axios';
import { SearchIcon } from '@heroicons/vue/outline';
import { StarIcon } from '@heroicons/vue/solid';
import Download from '@/components/Downloads.vue';
 
export default {
  name: 'LandingPage',
  components: {
    SearchIcon,
    StarIcon,
    Download,
  },
 
  data() {
    return {
      vendors: [],
      settings: null,
      base_url: this.$store.state.baseUrl,
    };
  },
 
  mounted() {
    this.fetchSettings();
    this.fetchVendors();
  },
 
  methods: {
    fetchSettings() {
      axios.get(`${this.base_url}api/app/settings`)
        .then(response => {
          this.settings = response.data;
        })
        .catch(error => {
          console.error('Error fetching settings:', error);
        });
    },
 
    fetchVendors() {
      axios.get(`${this.base_url}api/vendor/types`)
        .then(response => {
          this.vendors = response.data;
        })
        .catch(error => {
          console.error('Error fetching vendors:', error);
        });
    },
 
    sanitizeTitle(title) {
      let slug = title.toLowerCase()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-');
 
      return slug;
    },
  },
};
</script>
 
<style scoped>
.hero {
  position: relative;
  width: 100%;
  height: 75vh;
  background-image: url('../assets/images/bg.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: whitesmoke;
  padding-top: 4rem; /* Add padding to prevent content from overlapping with the navbar */
}
 
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: inherit;
  background-size: cover;
  background-position: center;
  filter: blur(5px);
  z-index: 2;
}
 
.smoke-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}
 
.hero-content {
  position: relative;
  z-index: 3;
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
 
@media (min-width: 1024px) {
  .hero-content {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding-left: 5rem;
  }
}
 
.left-content {
  width: 100%;
  padding: 2rem;
}
 
.left-content h1 {
  font-size: 2rem;
  font-weight: 900;
  color: #333333;
}
 
.left-content p {
  font-size: 1rem;
  font-weight: 100;
  color: #666666;
}
 
.button-group {
  margin-top: 1rem;
}
 
.cta-button,
.cta2-button {
  background-color: #ff7f50;
  border: none;
  color: white;
  padding: 0.75rem 1.5rem; /* Adjusted padding for better appearance */
  margin-right: 0.5rem;
  cursor: pointer;
  border-radius: 25px;
  display: inline-block; /* Ensures buttons are inline elements */
  text-align: center; /* Centers the text inside the buttons */
}
 
.cta2-button {
  background-color: #333;
}
 
.right-content {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
 
.right-image {
  width: 80%; /* Set the width to 80% for all screens */
  max-width: 500px; /* Ensure the image doesn't exceed 500px */
  height: auto;
}
 
@media (max-width: 1024px) {
  .hero {
    height: auto;
    padding-top: 6rem; /* Increased padding for small screens to avoid navbar overlap */
  }
 
  .left-content h1 {
    font-size: 1.5rem; /* Smaller font size for small screens */
  }
 
  .left-content p {
    font-size: 0.875rem; /* Smaller font size for small screens */
  }
 
  .cta-button,
  .cta2-button {
    padding: 0.5rem 1rem; /* Adjust padding for smaller buttons */
  }
 
  .right-content {
    width: 100%;
    padding: 1rem; /* Adjust padding for small screens */
  }
 
  .right-image {
    width: 70%; /* Adjust the image width for small screens */
  }
}
 
@media (max-width: 768px) {
  .hero-content {
    padding: 1rem;
  }
 
  .left-content h1 {
    font-size: 1.25rem; /* Smaller font size for extra small screens */
  }
 
  .left-content p {
    font-size: 0.75rem; /* Smaller font size for extra small screens */
  }
 
  .cta-button,
  .cta2-button {
    padding: 0.25rem 0.5rem; /* Adjust padding for extra small buttons */
  }
 
  .right-image {
    width: 60%; /* Adjust the image width for extra small screens */
  }
}
 
@media (max-width: 480px) {
  .hero-content {
    padding: 0.5rem;
  }
 
  .left-content h1 {
    font-size: 1rem; /* Smaller font size for very small screens */
  }
 
  .left-content p {
    font-size: 0.625rem; /* Smaller font size for very small screens */
  }
 
  .cta-button,
  .cta2-button {
    padding: 0.25rem 0.5rem; /* Adjust padding for very small buttons */
  }
 
  .right-image {
    width: 50%; /* Adjust the image width for very small screens */
  }
}
 
</style>
 